export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,

  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,

  OPTIMISM = 10,
  OPTIMISTIC_KOVAN = 69,

  BSC_MAINET = 56,
  BSC_TESTNET = 97,

  POLYGON_MAINET = 137,
  POLYGON_TESTNET = 80001,

  TOMOCHAIN_MAINNET = 88,
  TOMOCHAIN_TESNET = 89,

  AVALANCHE = 43114,
  AVALANCHE_TESTNET = 43113,
}
